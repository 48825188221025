<template>
    <div v-if="!currentUser || isAppLoading" class="app-overlay-wrapper">
        <loading-overlay large :show="true" :text="loadingOverlayText" />
    </div>
    <div class="app" id="app" v-else-if="currentUser">
        <div class="app-header"><top-nav-bar /></div>
        <!-- app-content components-->
        <template><router-view></router-view></template>
        <div class="app-footer"><b-footer /></div>
        <idle-dialog />
        <b-toast
            id="app-notification"
            ref="notificationComponent"
            solid
            toaster="b-toaster-bottom-right"
            :no-auto-hide="!notification.autoHide"
            :no-close-button="!notification.manualClose"
            :toast-class="{ short: !notification.title }"
            :auto-hide-delay="autoHideDelay"
            @hidden="onNotificationHidden"
        >
            <!-- notification.type corresponds to IconType -->
            <blurb small v-if="notification" :type="notification.type">
                <template v-if="notification.title" v-slot:shortMessage>{{ notification.title }} </template>
                <template v-slot:longMessage
                    >{{ notification.text }}
                    <div v-if="!!notification.error" class="error-details">
                        <a href="#" @click="copyErrorDetails(notification.error)">Copy details to clipboard</a>
                        <label v-if="notification.error.id">Error #{{ notification.error.id }}</label>
                    </div>
                </template>
            </blurb>
        </b-toast>
    </div>
</template>

<script setup lang="ts">
import TopNavBar from '@/common/components/navigation/top-navbar.vue'
import BFooter from '@/common/components/navigation/b-footer.vue'
import IdleDialog from '@/common/components/idle-dialog.vue'
import { authService, sessionService, NotificationDetails, ErrorDetails } from '@/common/services'
import { config } from './config'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router/composables'
import useClipboard from 'vue-clipboard3'
import { useAppStore, useCurrentUserStore } from '@/common/store'

//#region DEFINE VARIABLES
const router = useRouter()
const store = useAppStore()
const currentUserStore = useCurrentUserStore()
const notificationComponent = ref()
const notification = ref<NotificationDetails>({} as NotificationDetails)
const sessionInitialized = ref(false)
const autoHideDelay = ref(3000)
const loadingOverlayText = config.app.regCheckOnly ? "Loading RegCheck" : "Loading Propel"
//#endregion

//#region WATCH
watch(() => store.appLoading, () => initializeCurrentSession())
watch(() => store.notificationDetails, (newDetails) => {
    if (notification.value.text && newDetails?.autoHide !== notification.value.autoHide) {
        // Showing an auto-hide notification on top of a no-auto-hide
        // causes the second notification either not to hide or to hide 
        // too quickly.  This is a workaround.
        // showToast.value = false
        notificationComponent.value.hide()
        window.setTimeout(() => {
            showNotification(newDetails)
        }, 1000)
    }
    // show only the first in any set of system-errors
    else if (!(notification.value.type === 'SystemError' && newDetails?.type === 'SystemError')) {
        showNotification(newDetails)
    }
})
//#endregion

//#region COMPUTED
const isAppLoading = computed(() => store.appLoading.isLoading)
const currentUser = computed(() => currentUserStore.currentUser)
//#endregion

//#region INITIALIZE
const { toClipboard } = useClipboard({ appendToBody: false }) 

initialize()

function initialize() {
    initializeCurrentSession()
}
//#endregion

function showNotification(details?: NotificationDetails | null) {
    if (details == null)
        return

    notification.value = details
    autoHideDelay.value = notification.value.text.length > 65 ? 7000 : 3000
    notificationComponent.value.show()
}

function onNotificationHidden() {
    notification.value = {} as NotificationDetails
}

function copyErrorDetails(error: ErrorDetails) {
    // Check to see if the error message is obscenely long. If so, set the error.message to the number of lines configured in the appsettings
    const messageLines = error.message.split("\r\n")
    if (messageLines.length > config.app.copyErrorMessageNumLines)
        error.message = messageLines.slice(0, config.app.copyErrorMessageNumLines).join("\r\n")

    const text = error.id ? `Error ${error.id}: ${error.message}` : error.message
    toClipboard(text)
}

//If the user is already logged in, this function will be called from the app.ts created() hook
//If the user is in the process of logging in from the login-redirect page logic in the router store.ts, we want this function to be called after the app is finished its inital loading
function initializeCurrentSession() {
    if (authService.hasAccessToken()) {
        sessionInitialized.value = true

        if (sessionService.hasActiveSession()) {
            // if session is still active, clear the timeout
            // since the user is no longer idle
            sessionService.clearLastActiveTime()
        }
        else {
            // if session has expired, return true, leave timeout
            // in place for other browser instances                
            authService.logout(router.currentRoute.fullPath)
        }

        sessionService.initializeTimeout()
        currentUserStore.getCurrentUser()
    }
}
</script>

<style lang="scss">
@import './common/scss/app.scss';

.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: $background-color;

    .app-header,
    .app-footer {
        flex: 0;
    }
    .app-content {
        display: flex;

        flex-grow: 1;
        overflow: hidden;

        > * {
            flex-grow: 1;
        }
    }
}

.app-overlay-wrapper {
    height: 100vh;
    position: relative;
}
</style>
